.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  height: 100vh;
  width: 100vw;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-image: linear-gradient(15deg,
    hsl(209deg 86% 25%) 0%,
    hsl(227deg 44% 35%) 30%,
    hsl(251deg 33% 41%) 43%,
    hsl(276deg 34% 42%) 53%,
    hsl(300deg 36% 42%) 61%,
    hsl(318deg 45% 46%) 68%,
    hsl(330deg 53% 51%) 75%,
    hsl(339deg 68% 56%) 80%,
    hsl(348deg 82% 61%) 85%,
    hsl(359deg 93% 66%) 89%,
    hsl(11deg 100% 63%) 93%,
    hsl(22deg 100% 59%) 97%,
    hsl(34deg 100% 50%) 100%);
}

h1 {
  font-size: calc(10px + 8vmin);
  margin: 0;
}
